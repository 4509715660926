<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="titleName">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <span>
                        <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file
                        excel đúng format, hoặc có thể tải ở
                        <a :href="urlExcel">đây</a>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span> <b>Bước 2:</b> Tiến hành Import </span>
                    <div style="padding-top: 15px">
                      <b-form-file
                        @change="selectFile"
                        v-model="file"
                        ref="file-input"
                        style="display: none"
                        id="file-input"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      ></b-form-file>
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button
                            :disabled="btnDisable"
                            variant="secondary"
                            @click="openFile('file-input')"
                            v-b-tooltip.hover
                            title="Chọn file"
                            ><i class="fas fa-paperclip"></i
                          ></b-button>
                        </b-input-group-prepend>

                        <b-form-input
                          type="text"
                          :disabled="btnDisable"
                          v-model="textFile"
                          placeholder="Hãy chọn một tập tin"
                          @click="openFile('file-input')"
                          v-on:keydown.prevent
                          style="caret-color: transparent; cursor: pointer"
                          autocomplete="off"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="secondary"
                            :disabled="!file"
                            v-b-tooltip.hover
                            @click="uploadFile"
                            title="Tải file"
                            ref="up_excel"
                          >
                            <i class="fas fa-upload text-primary"></i>
                          </b-button>
                          <b-button
                            variant="secondary"
                            :disabled="!file"
                            @click="clearFiles"
                            v-b-tooltip.hover
                            title="Xoá file"
                            ><i class="fas fa-trash text-danger"></i
                          ></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <label class="description" style="font-size: 10px">
                        Chỉ nhận file csv, xls, xlsx và nhỏ hơn 5MB</label
                      >
                    </div>
                    <!-- <div v-show="file" class="mt-4">
                      <b-progress :max="100">
                        <b-progress-bar
                          :value="processBar"
                          :label="`${processBar.toFixed(2)}%`"
                        ></b-progress-bar>
                      </b-progress>
                    </div> -->
                  </b-col>
                </b-row>
              </b-container>
              <!-- End input session -->

              <b-container class="mt-10" v-show="importItems.length > 0">
                <hr
                  class="hr-text"
                  data-content="Danh sách"
                  style="font-weight: 600"
                />
                <div class="title-sum">
                  <ul class="list-inline d-flex">
                    <li>
                      <b
                        >Tổng tiền:
                        <span class="text-success">{{
                          convertPrice(sumAmount)
                        }}</span></b
                      >
                    </li>
                    <li>
                      <b
                        >Tổng thu:
                        <span class="text-primary">{{
                          convertPrice(sumInAmount)
                        }}</span></b
                      >
                    </li>
                    <li>
                      <b
                        >Tổng chi:
                        <span class="text-warning">{{
                          convertPrice(sumOutAmount)
                        }}</span></b
                      >
                    </li>
                  </ul>
                </div>
                <b-table
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fields"
                  :items="importItems"
                  id="my-table"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(createdAt)="row">
                    <span class="th-date">
                      {{ convertDate(row.item.createdAt) }}</span
                    >
                  </template>
                  <template v-slot:cell(amount)="row">
                    <b> {{ convertPrice(row.item.amount) }}</b>
                    <i
                      v-if="row.item.remainMoney < row.item.amount"
                      class="fas fa-exclamation-circle text-danger ml-1"
                      v-b-tooltip.hover
                      title="Vượt quá số tiền đã thanh toán"
                    ></i>
                  </template>
                  <template v-slot:cell(customerName)="row">
                    <b> {{ row.item.customerName }}</b>
                    <p>{{ row.item.customerMobile }}</p>
                  </template>
                </b-table>
                <b-col class="row mt-3">
                  <b>
                    Tổng số dòng:
                    {{ importItems ? importItems.length : 0 }}
                  </b>
                </b-col>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                  align="right"
                ></b-pagination>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <div class="bv-example-row container">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="create"
                  :disabled="btnDisable"
                  v-show="importItems.length > 0"
                  class="mr-2"
                  ><b>Cập nhật</b></b-button
                >
                <b-button
                  style="font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  :disabled="btnDisable"
                  @click="$router.go(-1)"
                  >Hủy</b-button
                >
              </b-row>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.myTable /deep/ .table {
  color: #464e5f;
  background-color: transparent;
}
.title-sum ul li {
  margin: 0px 10px;
}
.title-sum ul li:first-child {
  margin-left: 0px;
}
.th-date {
  font-size: 12px;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import axios from 'axios';
import { BASE_URL, TRANSACTION_TYPE } from './../../../utils/constants';
import moment from 'moment';
import _ from 'lodash';
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
  verticalAlign: 'middle'
};

export default {
  data() {
    return {
      fields: [
        {
          key: 'createdAt',
          label: 'Ngày',
          thStyle: {
            ...thStyleCommon,
            width: '7%'
          },
          tdClass: 'text-left',
          formatter: value => {
            return moment(value).format('YYYY-MM-DD');
          }
        },
        {
          key: 'typeName',
          label: 'Loại phiếu',
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'accountantId',
          label: 'Mã tài khoản kế toán',
          thStyle: {
            ...thStyleCommon,
            width: '7%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'contactTypeName',
          label: 'Loại đối tượng',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'customerName',
          label: 'Đối tượng nộp',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-left '
        },
        {
          key: 'amount',
          label: 'Thanh toán',
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-right',
          formatter: value => {
            return this.convertPrice(value);
          }
        },
        {
          key: 'debtAmount',
          label: 'Số tiền nợ',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-right',
          formatter: value => {
            return this.convertPrice(value);
          }
        },
        {
          key: 'paidAmount',
          label: 'Đã thanh toán',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-right',
          formatter: value => {
            return this.convertPrice(value);
          }
        },
        {
          key: 'remainMoney',
          label: 'Số tiền còn lại',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-right',
          formatter: value => {
            return this.convertPrice(value);
          }
        },
        {
          key: 'reconciliationDocTypeName',
          label: 'Loại chứng từ',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'reconciliationDocId',
          label: 'Chứng từ',
          thStyle: {
            ...thStyleCommon,
            width: '8%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'note',
          label: 'Diễn giải',
          thStyle: {
            ...thStyleCommon,
            width: '20%'
          },
          tdClass: 'text-left'
        }
      ],
      importItems: [],
      file: null,
      processBar: 0,
      currentPage: 1,
      urlExcel: BASE_URL + 'media/upload/debt/Temp_Import_Debt_.xls',
      titleName: 'Nhập excel',
      textFile: '',
      btnDisable: false
    };
  },
  components: {
    KTCodePreview
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    sumAmount() {
      return _.reduce(
        this.importItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0
      );
    },
    sumInAmount() {
      const outItems = _.filter(this.importItems, item => {
        return this.isShowAmount(item.type, 'in');
      });
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0
      );
    },
    sumOutAmount() {
      const outItems = _.filter(this.importItems, item => {
        return this.isShowAmount(item.type, 'out');
      });
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0
      );
    }
  },
  mounted() {
    const titleName = {
      items: 'Công nợ trả góp hoá đơn',
      credits: 'Công nợ quẹt thẻ hoá đơn',
      shipfee: 'Công nợ phí ship'
    };
    const mode = this.$route.params.mode;
    this.titleName =
      this.titleName +
      ' ' +
      titleName[mode].charAt(0).toLowerCase() +
      titleName[mode].slice(1);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: titleName[mode], route: `../../debts/${mode}` },
      { title: this.titleName }
    ]);
  },
  methods: {
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    convertDate: function(date, sfm) {
      if (!sfm) sfm = 'YYYY-MM-DD';
      return moment(date).format(sfm);
    },
    create: async function() {
      if (!this.btnDisable) {
        this.btnDisable = true;
        const params = {
          listTransaction: this.importItems
        };
        ApiService.setHeader();
        ApiService.post('transactions/import-excel/create', params)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              //this.$router.go(-1);
              this.makeToastSuccess(message || 'Thành công');
              this.importItems = [];
            } else {
              this.makeToastFaile(message);
            }
            this.btnDisable = false;
            this.$nprogress.done();
          })
          .catch(error => {
            this.btnDisable = false;
            this.$nprogress.done();
            if (error.response) {
              this.makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi'
              );
            } else {
              this.makeToastFaile('Lỗi');
            }
          });
      }
    },
    uploadFile() {
      if (!this.btnDisable) {
        this.btnDisable = true;
        const submitButton = this.$refs['up_excel'];
        this.$refs['up_excel'].innerHTML = '';
        submitButton.classList.add(
          'spinner',
          'spinner-primary',
          'spinner-md',
          'spinner-center',
          'px-7'
        );
        let formData = new FormData();
        const file = this.file;
        const route = this.$route;

        let path = route.path.split('/');
        path = 'upload/' + path[path.length - 2];

        formData.append('path', path);
        formData.append('mode', route.params.mode);
        formData.append('file', file);

        const url = BASE_URL + 'debts/import-excel';

        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data;'
            },
            timeout: 10000 * 60
          })
          .then(rs => {
            this.btnDisable = false;
            this.$nprogress.done();
            this.$refs['up_excel'].innerHTML =
              '<i class="fas fa-upload text-primary"></i>';
            submitButton.classList.remove(
              'spinner',
              'spinner-primary',
              'spinner-md',
              'spinner-center',
              'px-7'
            );

            if (rs.data.status) {
              this.importItems = rs.data.data;
            }
            this.makeToastSuccess('Đọc file thành công');
          })
          .catch(error => {
            setTimeout(() => {
              // send login request
              this.btnDisable = false;
              this.$refs['up_excel'].innerHTML =
                '<i class="fas fa-upload text-primary"></i>';
              this.$nprogress.done();
              submitButton.classList.remove(
                'spinner',
                'spinner-primary',
                'spinner-md',
                'spinner-center',
                'px-7'
              );
              if (error.response) {
                this.makeToastFaile(
                  error.response.data ? error.response.data.message : 'Lỗi'
                );
              } else {
                this.makeToastFaile(
                  'Vui lòng chọn lại file hoặc kiểm tra kết nối mạng'
                );
              }
            }, 500);
          });
      } else {
        this.makeToastFaile('File đang được tải lên ...');
      }
    },
    selectFile(event) {
      const files = event.target.files[0];

      if (!files) {
        this.textFile = '';
        return;
      }
      this.processBar = 0;
      if (!/(xls|xlsx|csv)$/.test(this.getExtension(files.name))) {
        this.clearFiles();
        return this.makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls .xlsx hoặc csv'
        );
      } else if (files.size > 5 * 1024 * 1024) {
        this.clearFiles();
        return this.makeToastFaile('Chỉ nhận file nhỏ hơn 5MB');
      }

      this.importItems = [];
      this.file = files;
      this.textFile = files.name;
    },
    clearFiles() {
      this.file = null;
      this.processBar = 0;
      this.$refs['file-input'].reset();
      this.textFile = '';
    },
    openFile(name) {
      document.getElementById(name).click();
    },
    getExtension(fname) {
      const pos = fname.lastIndexOf('.');
      const strlen = fname.length;
      let extension = '';
      if (pos != -1 && strlen != pos + 1) {
        let ext = fname.split('.');
        let len = ext.length;
        extension = ext[len - 1].toLowerCase();
      } else {
        return this.makeToastFaile('File không đúng định dạng');
      }
      return extension;
    },
    isShowAmount: function(type, mode = 'in') {
      const arrCheck = _.filter(TRANSACTION_TYPE, ['mode', mode]);
      return _.map(arrCheck, 'value').findIndex(x => x == type) > -1;
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    }
  }
};
</script>
